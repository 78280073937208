<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 20:08:19
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-24 17:53:06
-->
<template>
  <body-layer>
    <div class="base">
      <div class="bs">
        <div class="bre">
          <div>当前位置：</div>
          <div>首页</div>
          <div class="gt">&gt;</div>
          <div class="last" @click="$router.push({ name: 'new' })">资讯</div>
        </div>
        <div class="title">{{ body.post_title }}</div>
        <div class="time"><img src="@/assets/images/time.png" alt=""> <span>{{ body.published_time }}</span></div>
        <div class="line"></div>
        <template v-if="show">
          <div class="contact" v-html="body.post_content"></div>
        </template>
        <template v-if="vip">
          <div class="contact">
            <div style="text-align: center;padding: 150px 0; color: red;font-weight: bold;">只有VIP用户才能查看！</div>
          </div>
        </template>

      </div>
      <div class="line"></div>
      <links></links>
    </div>
  </body-layer>
</template>

<script>
import http from '@/util/http'
import bodyLayer from '@/components/bodyLayer.vue';
import links from '@/components/links.vue';
import { mapState } from 'vuex';
export default {
  components: { bodyLayer, links },
  data() {
    return {
      id: '',
      body: '',
      items: '',
      show: false,
      vip: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    async getData() {
      var params = {
        id: this.id
      }
      var { data } = await http.post('api/portal/getPortal.html', params);
      this.body = data.article;
      if (this.body.is_vip == 1) {
        if (this.$store.getters.uniqid && this.userInfo.is_vip == 1) {
          this.show = true;
          this.vip = false;
        } else {
          this.show = false;
          this.vip = true;
        }
      } else {
        this.show = true;
        this.vip = false;
      }


    },
  },
}
</script>

<style>
.contact img {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 140px 30px;
  padding-top: 30px;
  position: relative;

  .bs {
    padding: 0 100px;

    .time {
      padding-top: 20px;

      span {
        font-size: 14px;
        color: #999999;
        line-height: 1;
      }

      img {
        vertical-align: middle;
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      padding-top: 30px;
    }

    .bre {
      height: 60px;
      line-height: 60px;
      color: #999999;
      font-size: 14px;
      display: flex;

      .last {
        font-weight: bold;
        color: #FF2F07;
        cursor: pointer;
      }

      .gt {
        padding: 0 10px;
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #E8E8E8;
    margin: 30px 0;
  }
}
</style>